import loadImage from 'blueimp-load-image'

import { PaginationInput } from '../../generated/graphql'
import { DataLimit } from '../constants'

export const getIsSsr = () => typeof window === 'undefined'
export const getRandomInt = (min: number, max: number) => {
  const newMin = Math.ceil(min)
  const newMax = Math.floor(max)
  return Math.floor(Math.random() * (newMax - newMin + 1)) + newMin
}

export const getFullUserName = (
  user?: { firstName?: string | null; lastName?: string | null } | null,
  fallback = ''
) => {
  if (user) {
    const userName = [user.firstName, user.lastName].filter(Boolean).join(' ')
    return userName.trim().length ? userName : fallback
  }
  return fallback
}

export const getIsMobile = (testTouchPoints?: boolean) => {
  if (typeof window === 'undefined') return false
  let isMobileOs = false

  // New iOS versions no longer report iPad in user agent
  if (navigator.userAgent.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent')
      isMobileOs = true
    } catch (e) {}
  } else {
    isMobileOs = !!navigator.userAgent.match(
      /\b(Android|webOS|i(?:Phone|Pad|Pod)|BlackBerry|Windows (?:Phone|Mobile))\b/i
    )
  }

  if (testTouchPoints) {
    try {
      return (
        isMobileOs &&
        // @ts-expect-error - msMaxTouchPoints should be valid value
        ((window.navigator.msMaxTouchPoints as boolean) ||
          window.navigator.maxTouchPoints ||
          'ontouchstart' in document.createElement('div'))
      )
    } catch (e) {}
  }

  return isMobileOs
}

export const fixRotationOfFile = (file: File) =>
  new Promise<Blob>((resolve) => {
    loadImage(
      file,
      (img) =>
        (img as HTMLCanvasElement).toBlob(
          (blob) => {
            if (blob) {
              resolve(blob)
            }
          },
          file.type,
          1
        ),
      {
        orientation: true,
        canvas: true,
        downsamplingRatio: 0,
        imageSmoothingEnabled: false,
      }
    )
  })

type PageFromOffset = {
  page: string | undefined | null
  limitPerPage: number
}

export const getPageFromOffset = ({ page, limitPerPage }: PageFromOffset) =>
  limitPerPage * (Number.parseInt(page || '1') - 1)

type PaginationArgs = {
  dataLimit?: keyof typeof DataLimit
  page?: string | null | undefined
  length?: number
  orderBy?: PaginationInput['orderBy']
  orderByDirection?: PaginationInput['orderByDirection']
}

export const getPagination = ({
  dataLimit,
  length,
  page,
  orderBy,
  orderByDirection,
}: PaginationArgs) => ({
  orderBy,
  orderByDirection,
  length: length ?? DataLimit[dataLimit!],
  from: getPageFromOffset({ limitPerPage: length ?? DataLimit[dataLimit!], page }),
})

export const generateAvatarName = (word: string) =>
  word
    .split(' ')
    .map((word) => word.charAt(0))
    .join('')
