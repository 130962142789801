import { useRouter } from 'next/router'
import * as React from 'react'

import { RoutesMap } from '../../constants'
import { useAuth } from '../../utils/auth'

interface UnauthUserTemplateProps {
  children: React.ReactNode
}

export const UnAuthTemplate: React.FC<UnauthUserTemplateProps> = ({ children }) => {
  const router = useRouter()
  const { isLoggedIn } = useAuth()

  React.useEffect(() => {
    if (isLoggedIn) {
      void router.replace((router.query.redirectUrl as string) || RoutesMap.dashboard)
    }
  }, [isLoggedIn, router])

  return <>{children}</>
}
