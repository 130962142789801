import { Badge, Button, Group, Stack, Text } from '@mantine/core'
import { differenceInCalendarDays } from 'date-fns'
import React from 'react'

import { useAuth } from '../../utils/auth'
import { useUserSubscription } from '../../utils/useUserSubscription'
import { PricingModalWithButton } from '../Pricing/PricingModal'

interface Props {
  hasSubscriptionPlan?: boolean
  hasRemainingCredits?: boolean
}

export const SubscriptionStatus: React.FC<Props> = ({
  hasSubscriptionPlan = true,
  hasRemainingCredits = true,
}) => {
  const {
    data: { User },
  } = useAuth()
  const { Subscription, currentSubscriptionPlan, isActiveTrial, currentPeriodEndDate } =
    useUserSubscription()

  const daysLeftToPeriodEnd = currentPeriodEndDate
    ? differenceInCalendarDays(currentPeriodEndDate, new Date())
    : ''

  const subscriptionActionButton = (
    <Group mt="md">
      <PricingModalWithButton>
        <Button variant="light" fullWidth color="teal">
          Go premium
        </Button>
      </PricingModalWithButton>
    </Group>
  )

  if (!currentSubscriptionPlan) {
    return subscriptionActionButton
  }

  const { credits, StripeProduct } = Subscription!
  const subscriptionTitle = StripeProduct?.name ?? 'Trial'

  return (
    <Stack>
      <Group position="center" spacing="md" w="100%">
        {hasSubscriptionPlan && (
          <Stack align="center" spacing="xs">
            <Text>Subscription plan</Text>
            <Badge color="green" variant="light" size="lg">
              {subscriptionTitle}
              {isActiveTrial && daysLeftToPeriodEnd ? ` - ${daysLeftToPeriodEnd} days left` : ''}
            </Badge>
          </Stack>
        )}
        {hasRemainingCredits && (
          <Stack align="center" spacing="xs">
            <Text>Credits left</Text>
            <Badge color="orange" variant="light" size={User.openAIToken ? 'md' : 'lg'}>
              {User.openAIToken ? 'Unlimited' : credits.toLocaleString()}
            </Badge>
          </Stack>
        )}
      </Group>
      {isActiveTrial && subscriptionActionButton}
    </Stack>
  )
}
