import {
  Avatar,
  Badge,
  Box,
  Button,
  Code,
  Group,
  Menu,
  Navbar,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core'
import '@tabler/icons-react'
import {
  IconBulb,
  IconCheckbox,
  IconLock,
  IconReceipt2,
  IconSearch,
  IconSelector,
  IconUser,
  IconUserCircle,
} from '@tabler/icons-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

import { UserRoleEnum, useConversationsQuery } from '../../../generated/graphql'
import { RoutesMap } from '../../constants'
import { useAuth } from '../../utils/auth'
import { ThemeToggle } from '../AppTemplate/ThemeToggle'
import { SignOut } from './SignOut'
import { SubscriptionStatus } from './SubscriptionStatus'
import { UserButton } from './UserButton'

const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 0,
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.colors.orange[2] })
      .background,
  },

  section: {
    marginBottom: theme.spacing.md,

    '&:not(:last-of-type)': {
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  },

  searchCode: {
    fontWeight: 700,
    fontSize: rem(10),
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: theme.fontSizes.xs,
    padding: `${rem(8)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  mainLinkInner: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: rem(20),
    height: rem(20),
    pointerEvents: 'none',
  },

  collections: {
    paddingLeft: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingRight: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: `calc(${theme.spacing.md} + ${rem(2)})`,
    paddingRight: theme.spacing.md,
    marginBottom: rem(5),
  },

  collectionLink: {
    display: 'block',
    padding: `${rem(8)} ${theme.spacing.xs}`,
    textDecoration: 'none',
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.xs,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    lineHeight: 1,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },
}))

const links = [
  { icon: IconBulb, label: 'Activity', notifications: 3 },
  { icon: IconCheckbox, label: 'Tasks', notifications: 4 },
  { icon: IconUser, label: 'Contacts' },
]

export function LeftMenuBar() {
  const { classes } = useStyles()
  const {
    data: { User },
    signout,
  } = useAuth()
  const router = useRouter()

  const collections = [
    { emoji: '🏠', label: 'Dashboard', href: RoutesMap.dashboard },
    { emoji: '🧙', label: 'Characters', href: RoutesMap.characters },
    { emoji: '📚', label: 'Documents', href: RoutesMap.documents },
    { emoji: '💬', label: 'Conversations', href: RoutesMap.conversations },
    { emoji: '✨', label: 'Bookmarked messages', href: RoutesMap.bookmarkedMessages },
    ...(User.role === UserRoleEnum.SuperAdmin
      ? [
          { emoji: '❤️', label: 'Matches', href: RoutesMap.matches },
          { emoji: '⌘', label: 'Hashtags', href: RoutesMap.hashtags },
          { emoji: '📦', label: 'Embed', href: RoutesMap.embedAdmin },
          { emoji: '👄', label: 'Surveys', href: RoutesMap.surveys },
        ]
      : []),
    { emoji: '💡', label: 'Community', href: RoutesMap.community },
    { emoji: '👤', label: 'Profile', href: RoutesMap.profile },
  ]

  const conversationsQuery = useConversationsQuery({
    variables: {
      filterData: {
        userId: User.id!,
      },
      pagination: {
        from: 0,
        length: 10,
      },
    },
  })

  const mainLinks = links.map((link) => (
    <UnstyledButton key={link.label} className={classes.mainLink}>
      <div className={classes.mainLinkInner}>
        <link.icon size={20} className={classes.mainLinkIcon} stroke={1.5} />
        <span>{link.label}</span>
      </div>
      {link.notifications && (
        <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
          {link.notifications}
        </Badge>
      )}
    </UnstyledButton>
  ))

  const collectionLinks = collections.map((collection) => (
    <Link href={collection.href ?? '/'} key={collection.label} className={classes.collectionLink}>
      <span style={{ marginRight: rem(9), fontSize: rem(16) }}>{collection.emoji}</span>{' '}
      {collection.label}
    </Link>
  ))

  const conversationLinks = conversationsQuery.data?.conversations.conversations.map(
    (conversation) => (
      <Link
        href={`${RoutesMap.conversation}/${conversation.id}`}
        key={conversation.id}
        className={classes.collectionLink}
      >
        <Group spacing="0.25rem">
          <Avatar
            radius="sm"
            size="xs"
            src={conversation.Character.ImageS3File?.thumbnailSignedUrl}
          />{' '}
          {conversation.title}
        </Group>
      </Link>
    )
  )

  return (
    <>
      <Navbar.Section grow component={ScrollArea} px="sm">
        <Navbar.Section className={classes.section}>
          <Menu shadow="md" width={200} position="bottom-end" closeOnClickOutside withinPortal>
            <Menu.Target>
              <UserButton
                image=""
                name={
                  User.firstName && User.firstName
                    ? `${User.firstName ?? ''} ${User.lastName ?? ''}`
                    : User.email ?? ''
                }
                email={User.email ?? ''}
                icon={<IconSelector size="0.9rem" stroke={1.5} />}
              />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>User</Menu.Label>
              <Menu.Item
                icon={<IconUserCircle size={14} />}
                onClick={() => {
                  void router.push(`${RoutesMap.profile}/general`)
                }}
              >
                General
              </Menu.Item>
              <Menu.Item
                icon={<IconLock size={14} />}
                onClick={() => {
                  void router.push(`${RoutesMap.profile}/password`)
                }}
              >
                Password
              </Menu.Item>
              <Menu.Item
                icon={<IconReceipt2 size={14} />}
                onClick={() => {
                  void router.push(`${RoutesMap.profile}/subscription`)
                }}
              >
                Subscription
              </Menu.Item>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
              <Menu.Item color="red" icon={<IconLock size={14} />} onClick={() => void signout()}>
                Log out
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Navbar.Section>
        {false && (
          <>
            <TextInput
              placeholder="Search"
              size="xs"
              icon={<IconSearch size="0.8rem" stroke={1.5} />}
              rightSectionWidth={70}
              rightSection={<Code className={classes.searchCode}>Ctrl + K</Code>}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              mb="sm"
            />

            <Navbar.Section className={classes.section}>
              <div className={classes.mainLinks}>{mainLinks}</div>
            </Navbar.Section>
          </>
        )}

        <Navbar.Section className={classes.section}>
          <Group className={classes.collectionsHeader} position="apart">
            <Text size="xs" weight={500} color="dimmed">
              Collections
            </Text>
            {/*<Tooltip label="Create collection" withArrow position="right">*/}
            {/*  <ActionIcon variant="default" size={18}>*/}
            {/*    <IconPlus size="0.8rem" stroke={1.5} />*/}
            {/*  </ActionIcon>*/}
            {/*</Tooltip>*/}
          </Group>
          <div className={classes.collections}>{collectionLinks}</div>
        </Navbar.Section>

        <Navbar.Section className={classes.section}>
          <Group className={classes.collectionsHeader} position="apart">
            <Text size="xs" weight={500} color="dimmed">
              Recent chats
            </Text>
            {/*<Tooltip label="New chat" withArrow position="right">*/}
            {/*  <ActionIcon variant="default" size={18}>*/}
            {/*    <IconPlus size="0.8rem" stroke={1.5} />*/}
            {/*  </ActionIcon>*/}
            {/*</Tooltip>*/}
          </Group>
          <div className={classes.collections}>{conversationLinks?.splice(0, 3)}</div>
        </Navbar.Section>

        <Navbar.Section className={classes.section}>
          <Group className={classes.collectionsHeader} position="apart">
            <Text size="xs" weight={500} color="dimmed">
              My Subscription
            </Text>
            {/*<Button size="xs" compact>Open</Button>*/}
            <Button
              variant="subtle"
              component={Link}
              size="xs"
              compact
              href={`${RoutesMap.profile}/subscription`}
            >
              Open
            </Button>
          </Group>
          <div className={classes.collections}>
            <Stack p="xs">
              <SubscriptionStatus />
            </Stack>
          </div>
        </Navbar.Section>
        <Group position="apart">
          <SignOut />
          <ThemeToggle />
        </Group>
        <Box p="lg" />
        <Box p="lg" />
      </Navbar.Section>
    </>
  )
}
