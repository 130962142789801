import {
  AppShell,
  Aside,
  Burger,
  Container,
  Footer,
  Group,
  Header,
  MediaQuery,
  Navbar,
  Stack,
  Text,
  Title,
  createStyles,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import * as React from 'react'

import { LeftMenuBar } from '../../components/LeftMenuBar'
import { usePreventScroll } from '../../utils/usePreventScroll'
import { useThemeColorSetter } from '../../utils/useThemeColorSetter'

export interface UserTemplateProps {
  title?: string
  titleContent?: React.ReactNode
  children: React.ReactNode
  aside?: React.ReactElement
}

const useStyles = createStyles(() => ({
  main: {
    minHeight: 'calc(var(--vh, 1vh) * 100);',
  },
}))

export const AppTemplate: React.FC<UserTemplateProps> = ({
  title,
  titleContent,
  children,
  aside,
}) => {
  const { classes, theme } = useStyles()
  const isLtSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const isLtLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`)
  const [opened, setOpened] = React.useState(false)
  useThemeColorSetter()
  usePreventScroll({ isDisabled: !isLtSm || !opened })

  return (
    <AppShell
      classNames={classes}
      fixed
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="0"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 300, lg: 350 }}
          withBorder={false}
        >
          <Navbar.Section p="sm">
            {isLtSm && (
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
              />
            )}
          </Navbar.Section>
          <LeftMenuBar />
        </Navbar>
      }
      aside={
        aside ||
        (undefined && (
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }} withBorder={false}>
              <Text>Application sidebar</Text>
            </Aside>
          </MediaQuery>
        ))
      }
      footer={
        undefined && (
          <Footer height={60} p="md">
            Application footer
          </Footer>
        )
      }
      layout="alt"
      header={
        isLtSm || title || titleContent ? (
          <Header height={{ base: 50, lg: 70 }} p="sm" withBorder={false}>
            <Container px={0} h="100%">
              <Group>
                {isLtSm && (
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                  />
                )}

                <Title lh={1} size={isLtSm ? 'x-large' : isLtLg ? 'xx-large' : 'xxx-large'}>
                  {title}
                </Title>
                <div style={{ flex: 1 }}>
                  <Group position="right">{titleContent}</Group>
                </div>
              </Group>
            </Container>
          </Header>
        ) : undefined
      }
    >
      <Container px={0} h="100%">
        <Stack spacing="xl" h="100%">
          {children}
        </Stack>
      </Container>
    </AppShell>
  )
}
