import { useRouter } from 'next/router'
import * as React from 'react'

import { RoutesMap } from '../../constants'
import { UseAuthProtectionProps, useAuthProtection } from '../../utils/auth'

export interface Props extends UseAuthProtectionProps {
  children: React.ReactNode
}

export const AuthTemplate: React.FC<Props> = ({ children, authGroup, onUnAuth }) => {
  const router = useRouter()

  const redirectToSignIn = React.useCallback(
    () => void router.push(`${RoutesMap.home}?redirectUrl=${router.asPath}`),
    [router]
  )
  const { isLoggedIn, isOnline } = useAuthProtection({
    authGroup,
    onUnAuth: onUnAuth ?? redirectToSignIn,
  })

  const shouldShowContentToUser = isLoggedIn || !isOnline

  return shouldShowContentToUser ? <>{children}</> : null
}
