import {
  Avatar,
  Group,
  Text,
  UnstyledButton,
  UnstyledButtonProps,
  createStyles,
} from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import * as React from 'react'

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.xs,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
  },
}))

interface UserButtonProps extends UnstyledButtonProps {
  image: string
  name: string
  email: string
  icon?: React.ReactNode
}

export const UserButton = React.forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, icon, ...others }: UserButtonProps, ref) => {
    const { classes } = useStyles()

    return (
      <UnstyledButton {...others} className={classes.user} ref={ref}>
        <Group>
          <Avatar src={image} radius="xl" />

          <div style={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {name}
            </Text>

            <Text color="dimmed" size="xs">
              {email}
            </Text>
          </div>

          {icon || <IconChevronRight size="0.9rem" stroke={1.5} />}
        </Group>
      </UnstyledButton>
    )
  }
)

UserButton.displayName = 'UserButton'
