import { Button } from '@mantine/core'
import React from 'react'

import { useAuth } from '../../utils/auth'

export const SignOut = () => {
  const { signout, signoutData } = useAuth()

  return (
    <Button
      color="red"
      compact
      variant="light"
      loading={signoutData?.loading}
      onClick={() => void signout()}
    >
      Sign out
    </Button>
  )
}
