import { Group, Switch, useMantineColorScheme, useMantineTheme } from '@mantine/core'
import { IconMoonStars, IconSun } from '@tabler/icons-react'

export function ThemeToggle() {
  const theme = useMantineTheme()
  const mantineColorScheme = useMantineColorScheme()

  return (
    <Group position="center">
      <Switch
        size="md"
        checked={mantineColorScheme.colorScheme === 'dark'}
        onChange={(event) =>
          mantineColorScheme.toggleColorScheme(!event.currentTarget.checked ? 'light' : 'dark')
        }
        color={theme.colorScheme === 'dark' ? 'gray' : 'dark'}
        onLabel={<IconSun size="1rem" stroke={2.5} color={theme.colors.yellow[4]} />}
        offLabel={<IconMoonStars size="1rem" stroke={2.5} color={theme.colors.blue[6]} />}
      />
    </Group>
  )
}
