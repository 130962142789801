import { useMantineTheme } from '@mantine/core'
import * as React from 'react'

export function useThemeColorSetter() {
  const theme = useMantineTheme()

  React.useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute('content', theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white)
    document
      .querySelector('meta[name="msapplication-TileColor"]')
      ?.setAttribute('content', theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white)
  }, [theme])
}
